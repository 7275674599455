import React, { useEffect, useState } from "react"
import { AppWrapper } from "../components/AppWrapper"
import { BackgroundSection } from "../components/Background/BackgroundSection"
import Footer from "../components/ui/Footer"
import { FormContact } from "../components/FormContact/FormContact"
import { Header } from "../components/Header"
import firebase from "gatsby-plugin-firebase"
import { ANALYTIC_EVENT_NAMES } from "../constants/analytics"
import {graphql} from "gatsby";

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default function IndexPage({ location }) {

  // analytics - page viewed event
  useEffect(() => {
    if(!firebase) {
        return;
    }
    firebase.analytics().logEvent(ANALYTIC_EVENT_NAMES.web_page_viewed)
    firebase.analytics().logEvent(ANALYTIC_EVENT_NAMES.web_contact_page_viewed)
  }, [firebase])

  return (
    <AppWrapper>
      <Header {...{ location: location.pathname }} />
      <div   {...{ style: { paddingTop: "200px", background: "#a7bcdb" }, }}>

      </div>
      <BackgroundSection entreprise>
        <FormContact />
      </BackgroundSection>
      <Footer />
    </AppWrapper>
  )
}
