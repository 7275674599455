import React, { useState, useEffect } from 'react';
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useMobile } from "../../hooks/hooks.js";
import { WidthContainer } from '../WidthContainer';
import sphere from "./sphere.webp";
import "../../styles/styles.css"
import { ToastContainer, toast } from 'react-toastify';
import firebase from "gatsby-plugin-firebase"
import { ANALYTIC_EVENT_NAMES } from "../../constants/analytics"
import {useTranslation} from "gatsby-plugin-react-i18next";

const Label = ({ label }: any) => {
    return (
        <div
            id="Label"
            className=""
            {...{
                style: {
                    // fontStyle: "italic",
                    fontWeight: 300,
                    // color: theme.colors.blueFooter,
                    marginBottom: "4px",
                    marginTop: "18px"
                },
            }}
        >
            {label}
        </div>
    )
}

const ContactForm = ({ }: any) => {
    const { t } = useTranslation();
    const { mobile } = useMobile()
    const [ formSent, setFormSent ] = useState<boolean>(false)
    const [ formUnsent, setFormUnsent ] = useState<boolean>(false)

    // analytics - form sent / unsent (error) event
    useEffect(() => {
        if(firebase && formSent) {
            firebase.analytics().logEvent(ANALYTIC_EVENT_NAMES.web_contact_form_sent)
            setFormSent(false)
        }else if (firebase && formUnsent) {
            firebase.analytics().logEvent(ANALYTIC_EVENT_NAMES.web_contact_form_unsent)
            setFormUnsent(false)
        }
    }, [firebase, formSent, formUnsent])

    const initialValues = {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        message: "",
    };

    const signInSchema = Yup.object().shape({
        firstName: Yup.string().required((`${t('contact.firstNameRequired')}`)),
        lastName: Yup.string().required((`${t('contact.lastNameRequired')}`)),
        email: Yup.string().email((`${t('contact.emailValid')}`)).required((`${t('contact.emailRequired')}`)),
        message: Yup.string().required((`${t('contact.messageRequired')}`)),
    });

    const fieldStyle = {
        fontWeight: "300",
        borderBottom: '2px solid #102543',
        padding: "8px",
        borderRadius: "2px",
        marginBottom: "8px",
        marginTop: "2px",
        backgroundColor: "#fff0",
        color: "#102543"
    }

    return (
        <div
            className=""
            {...{
                id: "ContactForm",
                style: {
                    padding: "48px 0",
                    borderRadius: "12px",
                    // marginTop: "-48px",
                    // marginBottom: "72px",
                    // marginLeft: "48px",
                    // marginRight: "48px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                },
            }}
        >
            <div
                id="form-container"
                className=""
                {...{
                    style: {
                        width: mobile ?
                            "clamp(0px, 70vw, 640px)" : "100%"
                    },
                }}>


                <Formik
                    initialValues={initialValues}
                    validationSchema={signInSchema}
                    onSubmit={(values) => {
                    }}
                >
                    {(formik) => {
                        const { errors, touched, isValid, dirty, setFieldValue, values } = formik;
                        // console.log({ values });
                        // console.log({ errors, touched, isValid, dirty, values });
                        // console.log({ errors, });

                        return (
                            <Form style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",

                            }}>


                                <Field
                                    name="lastName"
                                    placeholder={t('contact.lastNamePlaceholder')}
                                    id="lastName"
                                    style={fieldStyle}

                                />
                                <ErrorMessage name="lastName" component="div" className="happizErrorField" />

                                <Field
                                    name="firstName"
                                    placeholder={t('contact.firstNamePlaceholder')}
                                    id="firstName"
                                    style={fieldStyle}
                                />
                                <ErrorMessage name="firstName" component="div" className="happizErrorField" />

                                <Field
                                    name="phone"
                                    placeholder={t('contact.phonePlaceholder')}
                                    id="phone"
                                    style={fieldStyle}

                                />
                                <ErrorMessage name="phone" component="div" className="happizErrorField" />

                                {/* <Label {...{ label: ("Adresse mail*") }} /> */}
                                <Field
                                    type="email"
                                    name="email"
                                    placeholder={t('contact.emailPlaceholder')}
                                    id="email"
                                    style={fieldStyle}

                                />
                                <ErrorMessage name="email" component="div" className="happizErrorField" />



                                <Field
                                    as="textarea"
                                    name="message"
                                    placeholder={t('contact.messagePlaceholder')}
                                    id="message"
                                    style={fieldStyle}
                                />
                                <ErrorMessage name="entreprise" component="div" className="happizErrorField" />

                                <div   {...{
                                    style: {
                                        fontWeight: 200,
                                        fontSize: "14px"
                                    },
                                }}>
                                    {t('contact.mandatoryFields')}
                                </div>

                                <button
                                    type="submit"
                                    disabled={!(dirty && isValid)}
                                    style={{
                                        width: "100%",
                                        height: "48px",
                                        fontWeight: "500",
                                        // border: `1px solid white`,
                                        borderRadius: "999px",
                                        padding: "6px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        background: "transparent linear-gradient(180deg, #366EC0 0%, #6288BF 100%) 0% 0% no-repeat padding-box",
                                        cursor: dirty && isValid ? "pointer" : "",
                                        margin: "24px 0 0 0",
                                        color: !(dirty && isValid) ? "#fff2" : "white"
                                    }}
                                    {...{
                                        onClick: () => {
                                            // console.log({ values });
                                            // TODO UPDATE ADDRESS TO PROD SERVER
                                            axios.post(`${process.env.GATSBY_MAILER_URL}/contact-form-mailer/contact`, {
                                                ...values,
                                            })
                                                .then((response) => {
                                                    // console.log({ response })
                                                    if (response.status === 201) {
                                                        setFormSent(true)
                                                        toast.info(`${t('messageSent')}`);
                                                        // navigate("thank-you")
                                                    }
                                                })
                                                .catch((error) => {
                                                    setFormUnsent(true)
                                                    toast.error(`${t('submissionError')}`);
                                                    // console.log({ error });
                                                })
                                        }
                                    }}
                                >
                                    {t('contact.sendButton')}
                                </button>

                            </Form>
                        );
                    }}
                </Formik >
            </div>
        </div>
    )
}


const FormContact = ({ }) => {
    const { mobile } = useMobile()
    const { t } = useTranslation();
    return (
        <div
            id="FormEntreprises"
            className=""
            {...{
                style: {
                    padding: "64px 0px 48px 0",
                },
            }}
        >
            <WidthContainer center>
                <div   {...{
                    style: {
                        display: "grid",
                        gridTemplate: mobile ? `
                        "a"
                        "b"
                        `: `
                        "a b"
                        / .7fr 1fr
                    `,
                        gap: "24px",
                        marginBottom: mobile ? "24px" : "96px",
                    },
                }}>
                    <div   {...{ style: {}, }}>
                        <div   {...{
                            style: {
                                fontSize: !mobile ? "36px" : "20px",
                                fontWeight: 700
                            },
                        }}>
                            {t('contact.needInformation')}
                        </div>
                        <div   {...{
                            style: {
                                fontSize: !mobile ? "36px" : "20px",
                                fontWeight: 700,
                                color: "#366EC0"
                            },
                        }}>
                            {t('contact.contactUs')}
                        </div>
                        <ContactForm />
                    </div>
                    <div   {...{
                        style: {
                            display: "grid",
                            gridTemplate: `
                            "center"
                        `,
                            placeItems: "center",
                            marginTop: "-20%"

                        },
                    }}>
                        <img src={sphere} alt="" style={{
                            gridArea: "center",
                        }} />
                        <div   {...{
                            style: {
                                gridArea: "center",
                                color: "white",
                                display: "grid",
                                gridTemplate: `
                                    "t1"
                                    "t2"
                                `,
                                gap: "12px",
                                alignItems: "center",
                                fontSize: mobile ? "16px" : "24px",
                                textAlign: mobile ? "center" : undefined
                            },
                        }}>
                            <div   {...{
                                style: {
                                    fontWeight: 700,
                                    fontSize: "24px"
                                },
                            }}>
                                {t('contact.directContact')}
                            </div>
                            {/* <HiOutlineMail /> */}
                            <div   {...{ style: {}, }}>
                                contact@behappiz.com
                            </div>
                        </div>
                    </div>
                </div>
            </WidthContainer>
        </div>
    )
}

export { FormContact };
